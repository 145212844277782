(function ($) {
	$(document).ready(function() {
			const toggleElements = ['[data-search-close]', '[data-search-icon]'];
			triggerSubmit();


			toggleElements.forEach(function(el) {
					toggleSearchBox(el);
			});
	});

	const toggleSearchBox = (selector) => {
			$(selector).click(function () {
					$('[data-search-overlay]').toggleClass('active');
					selectSearchBox();
					clearSearchBox();
					blurTheSearchBox();
			});

			$(document).keyup(function(e) {
					if (e.keyCode == 27 && $('[data-search-overlay]').hasClass('active')) {
						 // user pressed escape key
						 $('[data-search-overlay]').removeClass('active');
				 }
		 });
	}

	const blurTheSearchBox = (selector = '[data-search-input]') => {
		const isActive = document.querySelector('.search-overlay:not(.active)');
		const searchBox = $(selector);

		if (isActive && searchBox) {
			searchBox.blur();
		}
	}

	const selectSearchBox = () => {
			const searchBox = $('[data-search-input]');
			searchBox
					.focus()
					.select();
	}

	const clearSearchBox = () => {
			const searchBox = $('[data-search-input]');
			searchBox.val('');
	}

	const triggerSubmit = () => {
			$('[data-search-enter-icon]').click(function() {
					$('[data-search-form]').submit();
			});
	}
}(window.jQuery));