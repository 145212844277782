(($) => {

	const initDetailsRunner = () => {
		const $detailsButton = $('.details-runner__button');
		const $detailsContainer = $('.details-runner__data');

		$detailsButton.click(function (e) {
			e.preventDefault();

			if ($(this).next($detailsContainer).hasClass('hidden')) {
				$(this).next($detailsContainer).removeClass('hidden')
			} else {
				$(this).next($detailsContainer).addClass('hidden');
			}
		});
	};

	$(document).ready(function () {
		initDetailsRunner();
	});

	$(document).on('updated_checkout', function() {
		initDetailsRunner();
	});

	$(document).on('updated_wc_div', function() {
		initDetailsRunner();
	});
})(window.jQuery);
