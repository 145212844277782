import {
    Swiper,
    Navigation,
    EffectFade,
    Autoplay,
    Thumbs
} from 'swiper';

Swiper.use([Navigation, Autoplay, EffectFade, Thumbs]);

const galleryContainers = document.querySelectorAll('.gallery-container');

galleryContainers.forEach(galleryContainer => {
    let defaults = {
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        spaceBetween: 10,
        watchSlidesProgress: true,
        allowTouchMove: false,
    }

    const gallery = new Swiper(galleryContainer, defaults);

    let navDefaults = {
        slidesPerView: '3',
        loop: true,
        loopedSlides: 20,
        centeredSlides: true,
        slideToClickedSlide: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        thumbs: {
            swiper: gallery,
        },
        breakpoints: {
            640: {
                slidesPerView: '4'
            },
            1024: {
                slidesPerView: '5'
            },
            1200: {
                slidesPerView: '7'
            }
        }
    };

    const navGallery = new Swiper(galleryContainer.nextElementSibling, navDefaults);
});
