($ => {
	const allContainers = ".tc-container-enabled";
	const weightFields = ".tc-inline-description";
	const weightData =
		".tc-container-enabled .tmcp-field-wrap.tc-active > .tc-inline-description";
	const weightInitial = "div.single-product";

	const setWeightData = elements => {
		$(elements).each(function(index, element) {
			const weight = parseFloat(element.innerHTML);

			if (!isNaN(weight)) {
				$(this).attr("data-weight", weight);
			}
		});
	};

	const getWeightData = elements => {
		const initialWeight = $(weightInitial).attr("data-initweight");
		let sum = 0;

		if (isFinite(initialWeight)) {
			sum += Number(initialWeight);
		}

		$(elements).each(function(index, element) {
			if (isFinite($(this).attr("data-weight"))) {
				sum += Number($(this).attr("data-weight"));
			}
		});

		return sum;
	};

	$("[data-scroll-offer-form]").on("click", function(e) {
		e.preventDefault();

		$("html, body").animate(
			{
				scrollTop:
					$($(this).attr("href")).offset().top -
					$(".header").outerHeight() -
					100
			},
			500
		);
	});

	const getCurrentConfigurationData = () => {
		let data = { price: false, weight: false, options: [] };

		// add price and weight
		const totalPrice = document.querySelector(
			".tc-epo-totals .tm-final-totals .final"
		);
		data.price = {
			label: "Konfigurierter Preis",
			value: totalPrice.textContent ?? "?"
		};

		const totalWeight = getWeightData(weightData);
		data.weight = {
			label: "Konfiguriertes Gewicht",
			value: (totalWeight ?? "?") + " Gramm"
		};

		$(allContainers).each(function() {
			const labelText = $(this)
				.find(".tm-epo-element-label")
				.text();
			let labelValue = $(this)
				.find(".tmcp-field-wrap.tc-active .tc-label")
				.text();

			if (labelValue === "") {
				labelValue = $(this)
					.find(".tmcp-field")
					.val();
			}

			// if labelValue ends with "Wh_0" do not add it to the options
			if (labelValue.endsWith("_0")) {
				return;
			}

			data.options.push({ label: labelText, value: labelValue });
		});

		return data;
	};

	$("[data-download-configuration-pdf]").on("click", function(e) {
		e.preventDefault();
		const params = new URLSearchParams({
			action: "generate_pdf",
			slug: "configuration-summary",
			data: JSON.stringify({
				id: $(this).data("download-configuration-pdf"),
				data: getCurrentConfigurationData()
			})
		});
		window.open(wkScriptVars.ajax_url + "?" + params.toString(), "_blank");
	});

	$(document).on("click", "[data-offer-add-to-cart]", function(e) {
		$('form.cart [type="submit"]').click();
	});

	const sendConfigDataToForm = () => {
		if (
			$('.config-field-product-configuration input[type="text"]').length > 0
		) {
			setWeightData(weightFields);
			const configurationData = getCurrentConfigurationData();

			const configurationLabels = [
				configurationData.price,
				configurationData.weight,
				...configurationData.options
			]
				.map(item => item.label + ": " + item.value)
				.join(", ");

			$('.config-field-product-configuration input[type="text"]').val(
				configurationLabels
			);
		}

		$('.config-field-product-link input[type="text"]').val(
			window.location.href
		);
	};

	const $submitButton = $(".offer-form__form").find('button[type="submit"]');

	$submitButton.on("click", function() {
		sendConfigDataToForm();
	});

	$(window).on("tc-epo-after-update", function(e, o) {
		setWeightData(weightFields);
		const configurationData = getCurrentConfigurationData();

		$("dd.tm-final-totals").append(
			'<div class="weight-amount-container"><div class="weight-amount-label">Gewicht</div><div class="weight-amount-value">' +
				configurationData.weight.value +
				"</div></div>"
		);
	});

	const $radioButtons = $(".tmcp-field-wrap");
	$radioButtons.each(function() {
		$(this).append('<div class="underlined"></div>');
	});

	$(window).on("tc-epo-after-update", function(e, o) {
		const $constraints = $(".total-box-container");
		const $follower = $constraints.find(".totals-add-to-cart, .offer-link");
		const followerHeight = $follower.outerHeight();
		const windowHeight = $(window).outerHeight();

		$(window)
			.unbind("scroll.wk-conf")
			.on("scroll.wk-conf", function() {
				let pos = $(window).scrollTop();
				let top = $constraints.offset().top - $(".header").outerHeight();
				const constraintsHeight = $constraints.outerHeight();

				// bail if $constraints are not in viewport
				if (
					top + constraintsHeight - followerHeight < pos ||
					top > pos + windowHeight
				) {
					return;
				}

				let offset = parseInt($(window).scrollTop() - top);

				if (offset > 0) {
					$follower.css("transform", "translateY(" + offset + "px)");
				} else {
					$follower.css("transform", "translateY(0px)");
				}
			});

		const $configNavButtons = $(".owl-nav > .button");
		const $configContainer = $(".product-configurator");

		const scrollToElement = (buttonElement, targetElement) => {
			buttonElement
				.unbind("click.wk-conf")
				.on("click.wk-conf", function(event) {
					e.preventDefault();

					$("html, body").animate(
						{
							scrollTop: $configContainer.offset().top - 20
						},
						500
					);
				});
		};

		scrollToElement($configNavButtons, $configContainer);
	});

	// listen for changes on a select field with the id "frame_size"

	$("#frame_size").on("change", function() {
		// get the selected value
		const selectedValue = $(this).val();

		// set the selected value as the value of a specific input field with id "input_6_15"
		if ($('.config-field-frame-size input[type="text"]').length > 0) {
			$('.config-field-frame-size input[type="text"]').val(selectedValue);
		}
	});
})(window.jQuery);
