(function ($) {
	const $mapDiv = $('#standorte_map');
	let gMarkers = [];

    const base_path = window.wkScriptVars.theme_dir_url;
    const icon_normal = base_path + '/assets/dist/images/marker-dot.svg';
    const icon_active = base_path + '/assets/dist/images/marker-pin.svg';

	const initMap = ($element) => {
		// find markers in the map element
		const $markers = $('#standorte_info').find('.marker');

		// create generic map
		const mapArgs = {
			zoom: $element.data('zoom') || 7,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
			mapTypeControl: false,
			styles: [
				{
				  "elementType": "labels.icon",
				  "stylers": [
					{
					  "visibility": "off"
					}
				  ]
				},
				{
				  "elementType": "labels.text.fill",
				  "stylers": [
					{
					  "color": "#333333"
					},
					{
					  "lightness": 40
					}
				  ]
				},
				{
				  "elementType": "labels.text.stroke",
				  "stylers": [
					{
					  "color": "#ffffff"
					},
					{
					  "lightness": 15
					},
					{
					  "visibility": "on"
					}
				  ]
				},
				{
				  "featureType": "administrative",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#fefefe"
					}
				  ]
				},
				{
				  "featureType": "administrative",
				  "elementType": "geometry.stroke",
				  "stylers": [
					{
					  "color": "#fefefe"
					},
					{
					  "lightness": 15
					},
					{
					  "weight": 1
					}
				  ]
				},
				{
				  "featureType": "administrative.country",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#d2d2d2"
					}
				  ]
				},
				{
				  "featureType": "landscape",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#f7f7f7"
					},
					{
					  "lightness": 20
					}
				  ]
				},
				{
				  "featureType": "poi",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#e5e5e5"
					},
					{
					  "lightness": 21
					}
				  ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#e5e5e5"
					}
				  ]
				},
				{
				  "featureType": "road.arterial",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#ffffff"
					},
					{
					  "lightness": 18
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "geometry.fill",
				  "stylers": [
					{
					  "color": "#ffffff"
					}
				  ]
				},
				{
				  "featureType": "road.highway",
				  "elementType": "geometry.stroke",
				  "stylers": [
					{
					  "color": "#ffffff"
					},
					{
					  "lightness": 29
					},
					{
					  "weight": 0.2
					}
				  ]
				},
				{
				  "featureType": "road.local",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#f8f8f8"
					},
					{
					  "lightness": 16
					}
				  ]
				},
				{
				  "featureType": "transit",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#f2f2f2"
					},
					{
					  "lightness": 19
					}
				  ]
				},
				{
				  "featureType": "water",
				  "elementType": "geometry",
				  "stylers": [
					{
					  "color": "#ededed"
					}
				  ]
				}
			  ]
		};
		const map = new google.maps.Map($element[0], mapArgs);

		// add markers
		map.markers = [];
		$markers.each(function() {
			initMarker($(this), map);
		});

        // delay for correct marker options
        setTimeout(function(){
            if(gMarkers.length) {
                // check if something active already
                let somethingActive = false;
                gMarkers.every(marker => {
                    if(marker.active === true) {
                        somethingActive = true;
                        return false;
                    }

                    return true;
                });

                if(somethingActive === false) {
                    // find first visible marker and set it as active on init
                    gMarkers.every(marker => {
                        if(marker.visible === true) {
                            
                            const $infoDiv = $('.standort-info[data-standort-id="' + marker.standortId + '"]');
                            const $allInfoDivs = $('.standort-info');

                            marker.setIcon(icon_active);
                            marker.active = true;

                            $allInfoDivs.removeClass('active');

                            if ($infoDiv.hasClass('active')) {
                                $infoDiv.removeClass('active');
                            } else {
                                $infoDiv.addClass('active');
                            }

                            return false;
                        }
                        return true;
                    });
                    
                }
                    
            }
        }, 200);

		// center map based on markers
		centerMap(map);

		// return map instance
		return map;
	};

	const initMarker = ($marker, map) => {
		// get position from marker
		const lat = $marker.data('lat');
		const lng = $marker.data('lng');
		const standortId = $marker.data('standort-id');
		const markerCategory = $marker.data('term-ids');
		const latLng = {
			lat: parseFloat(lat),
			lng: parseFloat(lng),
		};

		// create marker instance
		const marker = new google.maps.Marker({
			position: latLng,
			icon: icon_normal,
			map: map,
			standortId: standortId,
			category: markerCategory,
            active: false,
		});

		// change marker icons on hover
		google.maps.event.addListener(marker, 'mouseover', function() {
			marker.setIcon(icon_active);
            console.dir(marker);
		});

		google.maps.event.addListener(marker, 'mouseout', function() {
            if(marker.active !== true) {
                marker.setIcon(icon_normal);
            }
		});

		// show marker info on click
		google.maps.event.addListener(marker, 'click', function () {
			const $infoDiv = $('.standort-info[data-standort-id="' + marker.standortId + '"]');
			const $allInfoDivs = $('.standort-info');

            gMarkers.forEach(marker => {
                marker.setIcon(icon_normal);
                marker.active = false;
            });

            marker.active = true;

			marker.setIcon(icon_active);

			$allInfoDivs.removeClass('active');

			if ($infoDiv.hasClass('active')) {
				$infoDiv.removeClass('active');
			} else {
				$infoDiv.addClass('active');
			}
		})

		// reference markers for later use
		map.markers.push(marker);
		gMarkers.push(marker);
	};

	const centerMap = (map) => {
		// create map boundaries from markers
		const bounds = new google.maps.LatLngBounds();
		map.markers.forEach(function(marker) {
			bounds.extend({
				lat: marker.position.lat(),
				lng: marker.position.lng(),
			});
		});

		// center if one marker
		if (map.markers.length == 1) {
			map.setCenter(bounds.getCenter());
		} else {
			map.fitBounds(bounds);
		}
	};

	const filterMarkers = (category) => {
		for (i = 0; i < gMarkers.length; i++) {
			marker = gMarkers[i];
			let markerCategory = [];

			if (typeof marker.category == 'number') {
				markerCategory.push(marker.category.toString());
			} else {
				markerCategory = marker.category.split(',');
			}

			if ((typeof markerCategory == 'object' && markerCategory.indexOf(category.toString()) >= 0) || category.length == 0) {
				marker.setVisible(true);
			} else {
				marker.setVisible(false);
			}
		}
	};

	// render the map on doc ready
	$(document).ready(function () {

        if($mapDiv.length) {
            $mapDiv.each(function () {
                const map = initMap($(this));
            });

            // switch marker categories on filter click
            $('.standorte-filter__link').on('click', function () {
                const filter = $(this).data('term-id');
                const filterName = $(this).data('term-name');
                const filterDefaultLocation = $(this).data('term-default-location');
                filterMarkers(filter);

                $('.standorte-filter__link').removeClass('active');
                $('.standorte-kategorie-description__item').removeClass('active');

                const termId = filter;
                $('.standorte-kategorie-description__item[data-term-id="' + termId.toString() + '"]').addClass('active');
                $(this).addClass('active');

                history.pushState(null,null,'#' + filterName);

                if(!!filterDefaultLocation) {
                    const $infoDiv = $('.standort-info[data-standort-id="' + filterDefaultLocation + '"]');
                    const $allInfoDivs = $('.standort-info');
                    let currentMarker = false;

                    gMarkers.forEach(marker => {
                        marker.setIcon(icon_normal);
                        marker.active = false;

                        if(filterDefaultLocation === marker.standortId) {
                            currentMarker = marker;
                        }
                    });

                    if(currentMarker !== false) {
                        currentMarker.active = true;

                        currentMarker.setIcon(icon_active);
    
                        $allInfoDivs.removeClass('active');
    
                        if ($infoDiv.hasClass('active')) {
                            $infoDiv.removeClass('active');
                        } else {
                            $infoDiv.addClass('active');
                        }
                    }
                   
                }
                // if ($('.standorte-kategorie-description__item').data('term-id') == $(this).data('term-id')) {
                // 	$('.standorte-kategorie-description__item').addClass('active')
                // }
            });

            // default marker category filter state
            if ($('.standorte-filter__link').hasClass('active')) {
                filterMarkers($('.standorte-filter__link.active').data('term-id'));
            }

            if(window.location.hash) {
                // Fragment exists
                $('.standorte-filter__link[data-term-name="' + window.location.hash.replace('#', '') + '"]').trigger('click');
            }
        }

	});

})(window.jQuery);
