// pollyfills
import "pwacompat/src/pwacompat"; // manifest compatibility
import AucorNavigation from "./aucorNavigation";
import objectFitImages from "object-fit-images";
import "what-input";
import "./ctaBanner";
import "./heroSlider";
import "./productGallery";
import "./impressionsGallery";
import "./testreviewSlider";
import "./imageModal";
import "./smoothScroll";
import "./searchForm";
import "./ambassadorSlider";
import "./blocks/accordion/frontend";
import "./productConfigurator";
import "./detailsRunner";
import "./standorte";
import "./postGrid";
import Sticky from "sticky-js";
import "./wkAddToCart";

($ => {
	objectFitImages();
	const $root = $("html, body");
	$(".scroll-down").on("click", function(e) {
		const heroHeaderHeight = $(".hero-header").outerHeight();
		e.preventDefault();
		$root.animate(
			{
				scrollTop: heroHeaderHeight
			},
			700,
			"linear"
		);
	});
	AucorNavigation(document.getElementById("primary-navigation"), {
		desktopMinWidth: 1201, // min width in pixels
		menuToggle: ".js-menu-toggle" // selector for toggle
	});
	const subMenu = $(".sub-menu");
	const menuIcon = $(".js-menu-caret");

	subMenu.hover(
		function() {
			$(this)
				.parent()
				.addClass("hovered");
		},
		function() {
			$(this)
				.parent()
				.removeClass("hovered");
		}
	);
	menuIcon.hover(
		function() {
			$(this)
				.parent()
				.addClass("hovered");
		},
		function() {
			$(this)
				.parent()
				.removeClass("hovered");
		}
	);

	new Sticky('[data-sticky-element="true"]', {
		marginTop: $(".header").height() + 60,
		stickyClass: "is-sticky"
	});

	$(document.body).on(
		"added_to_cart removed_from_cart updated_cart_totals",
		(e, fragments, cart_hash, this_button) => {
			if (fragments !== undefined && "span.header_cart_count" in fragments) {
				if (fragments["span.header_cart_count"].includes("(0)")) {
					$("span.header_cart_count").removeClass("has-items");
				} else {
					$("span.header_cart_count").addClass("has-items");
				}
			}
		}
	);

	$(document.body).on("wc_cart_emptied", () => {
		$("span.header_cart_count").removeClass("has-items");
	});

	$(document).ready(() => {
		if (
			!$("span.header_cart_count")
				.html()
				.includes("(0)")
		) {
			$("span.header_cart_count").addClass("has-items");
		}
	});
})(window.jQuery);
