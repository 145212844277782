import {
	Swiper,
	Navigation,
	Pagination,
	Autoplay,
	Mousewheel,
	Scrollbar,
	EffectFade,
	EffectCoverflow
} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, Mousewheel, Scrollbar, EffectFade, EffectCoverflow]);

(function ($) {
	"use strict";
	const Swipers = [];


	$('.swiper-container').each(function () {
			let defaults = {
					// Navigation cannot be set here otherwise multiple sliders won't work
					slides: 1,
					navigation: {
							nextEl: $(this).find('.swiper-button-next')[0],
							prevEl: $(this).find('.swiper-button-prev')[0],
					},
					pagination: {
							el: $(this).find('.swiper-pagination'),
							clickable: true,
							type: "bullets",
					},
					loop: true,
					slidesPerView: 1,
			};

			if ($(this).data().titlenavigation) {
					if ($(this).data().pagination && $(this).data().pagination.type === 'fraction') {
							defaults.pagination = {
									el: '.swiper-pagination',
									clickable: true,
									type: 'custom',
									renderCustom: function (swiper, current, total) {
											let pagination = '<div class="swiper-titlepagination-wrapper">', i;
											for (i = 0; i < total; i++) {
													let title = $(swiper.$el).find('.swiper-slide:eq(' + i + ')').data('slide-title');
													if (i === current - 1) {
															pagination += '<span class="swiper-pagination-title swiper-pagination-title-active"><span class="slide-title">' + title + '</span></span>';
													} else {
															pagination += '<span class="swiper-pagination-title"><span class="slide-title">' + title + '</span></span>';
													}

											}
											pagination += '</div><div class="swiper-fractionpagination-wrapper"><span class="swiper-pagination-fraction">' + current + '/' + total + '</span></div>';
											return pagination;
									}
							};
							// unset pagination so it does not overwrite our custom setting
							delete $(this).data().pagination;
					} else {
							defaults.pagination = {
									el: '.swiper-pagination',
									clickable: true,
									type: 'bullets',
									renderBullet: function (index, className) {
											const title = $(this.$el).find('.swiper-slide:eq(' + index + ')').data('slide-title');
											return '<span class="' + className + '"><span class="slide-title swiper-no-swiping">' + title + '</span></span>';
									}
							};
					}
			}

			const data = $(this).data() ? $(this).data() : {};

			const swiperOptions = Object.assign({}, defaults, data);

			Swipers.push(new Swiper($(this)[0], swiperOptions));
	});
})(window.jQuery);
