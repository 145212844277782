(($) => {
	const setCookie = function (cname, cvalue, exdays) {
		let d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		let expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	};

	const getCookie = function (cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return false;
	};

	const ctaBanner = $('.cta-banner');

	if (!getCookie('transalpesCtaBanner')) {
		ctaBanner.show();
	}

	$('.js-close-banner').each(function () {
		this.addEventListener('click', () => {
			setCookie('transalpesCtaBanner', 'true', 1);
			ctaBanner.slideToggle();
		});
	});

})(window.jQuery);
