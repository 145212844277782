($ => {
	$(document).ready(function() {
		const $addToCartButton = document.getElementById("wk_add_to_cart");
		const $frameSizeSelect = $("#frame_size");

		if ($addToCartButton && $frameSizeSelect) {
			$frameSizeSelect.on("change", function(e) {
				if (this.value.length > 0) {
					if (!/&frame_size=/i.test($addToCartButton.href)) {
						// when addToCartButton href does not contain &frame_size parameter, add it
						$addToCartButton.setAttribute(
							"href",
							$addToCartButton.href + "&frame_size=" + this.value
						);
					} else {
						// when addToCartButton href contains &frame_size parameter, replace it
						$addToCartButton.href = $addToCartButton.href.replace(
							/&frame_size=.*$/i,
							"&frame_size=" + this.value
						);
					}
				} else {
					// when addToCartButton href does not contain &frame_size parameter, remove it
					$addToCartButton.href = $addToCartButton.href.replace(
						/&frame_size=.*$/i,
						""
					);
				}
			});

			// validate frame size on add to cart button click
			$addToCartButton.addEventListener("click", function(e) {
				if ($frameSizeSelect.val().length === 0) {
					e.preventDefault();
					alert("Bitte wähle eine Rahmengrösse aus.");
				}
			});
		}
	});
})(window.jQuery);
