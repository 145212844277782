/**
 * Aucor Navigation.js
 * -------------------
 *
 * Features:
 * - only adds classes, no show() or hide()
 * - timer for hover exit: better usability
 * - works with tabs: a11y
 * - desktop menu with touch: doubletap
 * - mobile menu with touch
 * - works at least with 3 levels (probably more)
 *
 */

import {
	disablePageScroll,
	enablePageScroll,
	setFillGapMethod
} from "scroll-lock";
import debouncedResize from "debounced-resize";

const AucorNavigation = (menu, options) => {
	// Default settings
	const defaults = {
		desktopMinWidth: 932,
		menuToggle: "#menu-toggle"
	};

	const settings = { ...defaults, ...options };

	const { desktopMinWidth } = settings;

	const menuToggle = document.querySelector(settings.menuToggle);

	let screenW;

	let hoverTimer;

	const closeOverlayOnLarge = () => {
		if (window.innerWidth > desktopMinWidth) {
			menu.classList.remove("active");
			menuToggle.classList.remove("menu-toggle--active");
			document
				.getElementById("mobile-navigation-overlay")
				.classList.remove("active");
			document.getElementById("js-menu-toggle").classList.remove("is-active");
			enablePageScroll(document.body);
		}
	};

	debouncedResize(() => {
		closeOverlayOnLarge();
	});

	const isDesktopMenu = () => {
		screenW = Math.max(
			document.documentElement.clientWidth,
			window.innerWidth || 0
		);
		if (screenW < desktopMinWidth) {
			return false;
		}
		return true;
	};

	const openSubMenu = event => {
		if (isDesktopMenu()) {
			// clear timer
			clearTimeout(hoverTimer);

			// make sure hoverTimer did it's thing even if it didn't have time to fire
			// -> close all .sub-menus that don't belong to this DOM tree
			const thisTreeSubmenus = [];

			// get submenus on tree parents
			let currentParent = event.target.parentElement;
			while (!currentParent.isEqualNode(menu)) {
				if (currentParent.classList.contains("sub-menu")) {
					thisTreeSubmenus.push(currentParent);
				}
				currentParent = currentParent.parentElement;
			}

			// get submenus on tree descendants
			const currentDescendants = event.target.querySelectorAll(".sub-menu");
			for (let d = 0; d < currentDescendants.length; d += 1) {
				thisTreeSubmenus.push(currentDescendants[d]);
			}

			// fetch all open submenus
			const allOpenSubMenus = menu.querySelectorAll(".open");
			for (let j = 0; j < allOpenSubMenus.length; j += 1) {
				// close the submenu only if not in current tree
				if (thisTreeSubmenus.indexOf(allOpenSubMenus[j]) === -1) {
					allOpenSubMenus[j].classList.remove("open");
				}
			}

			// open child sub-menu
			if (event.target.querySelector(".sub-menu")) {
				event.target.querySelector(".sub-menu").classList.add("open");
			}
		}
	};

	const closeSubMenu = event => {
		const t = event.target;
		// create timeout that let's the cursor get outside of menu for a moment
		if (isDesktopMenu()) {
			hoverTimer = setTimeout(() => {
				let parent = t.parentElement;
				while (!parent.isEqualNode(menu)) {
					parent.classList.remove("open");
					parent = parent.parentElement;
				}
				if (t.querySelector(".open")) {
					t.querySelector(".open").classList.remove("open");
				}
			}, 750);
		}
	};

	const openSubmenuWithClick = e => {
		let li = null;
		let parent = e.target.parentElement;
		while (!parent.isEqualNode(menu)) {
			if (parent.classList.contains("menu-item")) {
				li = parent;
				break;
			}
			parent = parent.parentElement;
		}

		// toggle .open class to child .sub-menu
		li.querySelector(".sub-menu").classList.toggle("open");

		// toggle .active class to this <li>
		if (!isDesktopMenu()) {
			li.classList.toggle("active");
		}

		// don't trigger parent(s)
		e.stopPropagation();
	};

	const itemsWithChildren = menu.querySelectorAll(".menu-item-has-children");
	for (let i = 0; i < itemsWithChildren.length; i += 1) {
		const item = itemsWithChildren[i];
		item.addEventListener("mouseover", openSubMenu);
		item.addEventListener("mouseleave", closeSubMenu);

		const caret = item.querySelector(".js-menu-caret");
		if (caret) {
			/* Open sub-menu with click to <button>
                  ----------------------------------------------- */
			caret.addEventListener("click", openSubmenuWithClick);
		}
	}

	/* Keyboard (tab)
      ----------------------------------------------- */
	const onLinkFocus = e => {
		// open sub-menu below
		const submenuBelow = e.target.parentElement.querySelector(".sub-menu");
		if (submenuBelow) {
			submenuBelow.classList.add("open");
		}

		// open all sub-menus above
		let parent = e.target.parentElement;
		while (!parent.isEqualNode(menu)) {
			if (parent.classList.contains("sub-menu")) {
				parent.classList.add("open");
			}
			parent = parent.parentElement;
		}
	};

	const onLinkBlur = e => {
		// close sub-menu below
		const submenuBelow = e.target.parentElement.querySelector(".sub-menu");
		if (submenuBelow) {
			submenuBelow.classList.remove("open");
		}

		// close all sub-menus above
		let parent = e.target.parentElement;
		while (!parent.isEqualNode(menu)) {
			if (parent.classList.contains("sub-menu")) {
				parent.classList.remove("open");
			}
			parent = parent.parentElement;
		}
	};

	const links = menu.querySelectorAll("a");
	for (let k = 0; k < links.length; k += 1) {
		const link = links[k];

		link.addEventListener("focus", onLinkFocus);
		link.addEventListener("blur", onLinkBlur);
	}

	/* Toggle menu (hamburger)
      ----------------------------------------------- */

	const toggleMenu = () => {
		if (menuToggle.classList.contains("menu-toggle--active")) {
			// remove .active class from hamburger icon
			menuToggle.classList.remove("menu-toggle--active");
			menuToggle.setAttribute("aria-expanded", "false");

			// remove .active class to menu container
			menu.classList.remove("active");
			document
				.getElementById("mobile-navigation-overlay")
				.classList.remove("active");
			document.getElementById("js-menu-toggle").classList.remove("is-active");
			enablePageScroll(document.body);

			// focus out of the menu
			menuToggle.dispatchEvent(new Event("focus"));
		} else {
			// .active class to hamburger icon
			menuToggle.classList.add("menu-toggle--active");
			menuToggle.setAttribute("aria-expanded", "true");

			// .active class to menu container
			menu.classList.add("active");
			document
				.getElementById("mobile-navigation-overlay")
				.classList.toggle("active");
			document.getElementById("js-menu-toggle").classList.toggle("is-active");
			disablePageScroll(document.body);
		}
	};
	if (
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/iPod/i)
	) {
		menuToggle.addEventListener("touchstart", toggleMenu);
	} else {
		menuToggle.addEventListener("click", toggleMenu);
	}

	/* Touch + desktop menu: doubletap
      ----------------------------------------------- */

	let touchStartFn;
	let maybeCloseMenuFn;

	if ("ontouchstart" in window) {
		const findAndRemoveClass = (container, className) => {
			const elements = container.querySelectorAll(`.${className}`);
			for (let e = 0; e < elements.length; e += 1) {
				elements[e].classList.remove(className);
			}
		};

		const targetInsideMenu = _elem => {
			const elem = _elem.parentElement;
			let isInsideMenu = false;
			while (elem !== null) {
				if (!(elem.nodeType !== Node.ELEMENT_NODE) && elem.isEqualNode(menu)) {
					isInsideMenu = true;
				}
			}
			return isInsideMenu;
		};

		// maybe close menu after it has been opened by tap
		maybeCloseMenuFn = e => {
			// if the target of the tap isn't menu nor a descendant of menu
			if (menu !== e.target && !targetInsideMenu(e.target) && isDesktopMenu()) {
				// reset menu state to default
				findAndRemoveClass(menu, "open");
				findAndRemoveClass(menu, "tapped");
				findAndRemoveClass(menu, "active");
			}

			// remove this event listener
			document.removeEventListener("ontouchstart", maybeCloseMenuFn, false);
		};

		touchStartFn = e => {
			// only fire on desktop menu
			if (!isDesktopMenu()) {
				return false;
			}

			const currentListItem = this.parentElement;
			let currentParent;

			if (!currentListItem.classList.contains("tapped")) {
				// first tap: don't go to <a> yet
				e.preventDefault();

				// remove .tapped class to <li> that don't belong to this DOM tree
				const thisParentsLi = [];
				currentParent = currentListItem;
				while (!currentParent.isEqualNode(menu)) {
					if (currentParent.classList.contains("tapped")) {
						thisParentsLi.push(currentParent);
					}
					currentParent = currentParent.parentElement;
				}
				const allTapped = menu.querySelectorAll(".tapped");
				for (let j = 0; j < allTapped.length; j += 1) {
					// Close the submenu only if not in current tree
					if (thisParentsLi.indexOf(allTapped[j]) === -1) {
						allTapped[j].classList.remove("tapped");
					}
				}

				// add .tapped class to <li> element
				currentListItem.classList.add("tapped");

				// close all .sub-menus that don't belong to this DOM tree
				const thisParentsSubmenu = [];
				currentParent = currentListItem;
				while (!currentParent.isEqualNode(menu)) {
					if (currentParent.classList.contains("open")) {
						thisParentsSubmenu.push(currentParent);
					}
					currentParent = currentParent.parentElement;
				}
				const allOpenSubMenus = menu.querySelectorAll(".open");
				for (let t = 0; t < allOpenSubMenus.length; t += 1) {
					// Close the submenu only if not in current tree
					if (thisParentsSubmenu.indexOf(allOpenSubMenus[t]) === -1) {
						allOpenSubMenus[t].classList.remove("open");
					}
				}

				// open .sub-menu below
				if (currentListItem.querySelector(".sub-menu")) {
					currentListItem.querySelector(".sub-menu").classList.add("open");
				}

				// open all .sub-menus above
				currentParent = this.parentElement;
				while (!currentParent.isEqualNode(menu)) {
					if (currentParent.classList.contains("sub-menu")) {
						currentParent.classList.add("open");
					}
					currentParent = currentParent.parentElement;
				}

				// add EventListener to second click
				document.addEventListener("touchstart", maybeCloseMenuFn, false);
			} else {
				// second tap: go to <a>

				// remove .tapped from current <li>
				currentListItem.classList.remove("tapped");

				// close .sub-menus
				findAndRemoveClass(menu, "open");
			}

			return false;
		};

		// add eventlisteners for each <a> with a sub-menu
		const parentLinks = menu.querySelectorAll(".menu-item-has-children > a");
		for (let p = 0; p < parentLinks.length; p += 1) {
			parentLinks[p].addEventListener("touchstart", touchStartFn, false);
		}
	}

	// make the call chainable
	return this;
};

export default AucorNavigation;
