(($) => {

	const update = ($postGrid, page) => {
		const $posts = $postGrid.find('.post-grid__posts');
		const $pagination = $postGrid.find('.post-grid__pagination');
		const $filterItems = $postGrid.find('.post-grid__filters__item');
		const postType = $postGrid.data('post-type');
		const additional = $postGrid.data('additional');

		const data = {
			postType,
			additional,
			page,
			filters: $filterItems
				.filter(function(i, filterItem) {
					return $(filterItem).find('button.active').data('id') !== '';
				})
				.map(function(i, filterItem) {
					return {
						taxonomy: $(filterItem).data('taxonomy'),
						id: $(filterItem).find('button.active').data('id'),
					};
				}).get(),
		};

		$postGrid.addClass('post-grid--loading');

		$.ajax({
			url: `${window.wkScriptVars.rest_url}post-grid/v1/fetch`,
			method: 'GET',
			data,
		}).then((res) => {
			$posts.html(res.posts);
			$pagination.html(res.pagination);
			$postGrid.removeClass('post-grid--loading');
		});
	}

	$('.post-grid').on('click', '.post-grid__pagination__number, .post-grid__pagination__arrow', function() {
		const $postGrid = $(this).closest('.post-grid');
		const page = $(this).data('page');

		update($postGrid, page);
	});

	$('.post-grid').on('click', '.post-grid__filters__item button', function() {
		const $postGrid = $(this).closest('.post-grid');
		$(this).closest('.post-grid__filters__item').find('button').removeClass('active');
		$(this).addClass('active');

		update($postGrid, 1);
	});
})(window.jQuery);
