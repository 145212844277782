import {
	Swiper,
	Navigation,
	Pagination,
	Autoplay,
	Mousewheel,
	Scrollbar,
	EffectFade,
	EffectCoverflow,
	Controller
} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, Mousewheel, Scrollbar, EffectFade, EffectCoverflow, Controller]);

(function ($) {
	"use strict";
	const Impressions = [];

	$('.impressions-container').each(function () {
		let defaults = {
			slides: 1,
			slidesPerView: 1,
			navigation: {
				nextEl: $(this).find('.swiper-button-next')[0],
				prevEl: $(this).find('.swiper-button-prev')[0],
			},
			loop: false,
			spaceBetween: 16,
			pagination: {
					el: $(this).find('.swiper-pagination')[0],
					clickable: true,
					type: "bullets",
			},
			noSwiping: true,
			noSwipingClass: "no-swiping",
		};

		const data = $(this).data() ? $(this).data() : {};

		const swiperOptions = Object.assign({}, defaults, data);

		Impressions.push(new Swiper($(this)[0], swiperOptions));
	});
})(window.jQuery);
