import MicroModal from 'micromodal/lib/src';
import {
	disablePageScroll,
	enablePageScroll,
} from 'scroll-lock';

(($) => {
 const $imageLinksToOpen = $('.pop-up');
 const $modalToOpenIn = $('#image-modal');
 const microModalConf = {
	 openTrigger: 'data-micromodal-open',
	 disableScroll: true,
	 openClass: 'is-open',
 };

 // add micromodal data attributes to micromodal related elements
 $(document).ready(function () {
	 if ($imageLinksToOpen.length) {
		$imageLinksToOpen.each(function () {
			$(this).attr('data-custom-open', 'image-modal');
			const imageToOpen = this.href;

			$(this).on('click', function (event) {
				event.preventDefault();
				$modalToOpenIn.find('.image-modal-placeholder').replaceWith('<img class="image-modal-placeholder" src="' + imageToOpen + '" />');
				MicroModal.show('image-modal');
				$('body').addClass('modal-is-open');
				disablePageScroll();
			});

			$('[data-micromodal-close]').on('click', function () {
				MicroModal.close('image-modal')
				$('body').removeClass('modal-is-open');
				enablePageScroll();
			});
		});

		setTimeout(function () {
			MicroModal.init(microModalConf);
		}, 200);


	 }
 });
})(window.jQuery);
