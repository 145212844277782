import {
	Swiper,
	Navigation,
	Pagination,
	Autoplay,
	Mousewheel,
	Scrollbar,
	EffectFade,
	EffectCoverflow
} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, Mousewheel, Scrollbar, EffectFade, EffectCoverflow]);

(function ($) {
	"use strict";
    const Impressions = [];
    
    $('.ambassador-container').each(function () {
		let defaults = {
			slidesPerView: 1.15,
			navigation: {
				nextEl: $('.single-ambassador__ambassadors').find('.swiper-button-next')[0],
				prevEl: $('.single-ambassador__ambassadors').find('.swiper-button-prev')[0],
			},
			loop: false,
			spaceBetween: 15,
			stagePadding: 100,
			breakpoints: {
				810: {
					slidesPerView: 3,
					spaceBetween: 30,
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 30,
				},
			},
		};

		const data = $(this).data() ? $(this).data() : {};

		const swiperOptions = Object.assign({}, defaults, data);

		Impressions.push(new Swiper($(this)[0], swiperOptions));
	});
})(window.jQuery);