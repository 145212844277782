import 'beefup/dist/js/jquery.beefup.min';

(function ($) {
    $('.openAll .accordion__item').beefup({
        trigger: '.accordion__header',
        content: '.accordion__body',
    });

    $('.openSingle .accordion__item').beefup({
        trigger: '.accordion__header',
        content: '.accordion__body',
        openSingle: true
    });
   
}(window.jQuery));