(function($) {
	// define element to be animated
	const $root = $("html, body");

	// prevent page to jump to anchor in onLoad scrollTo case
	let target = window.location.hash; // store hash from url to a variable
	target = target.replace("#", ""); // remove hash character from variable

	// on click scroll to id defined in the clicked element
	$('a[href*="#"]').click(function(e) {
		const hash = e.currentTarget.href.split("#");
		if (hash[1] !== "") {
			const elToScroll = document.querySelector("#" + hash[1]);
			if (!!elToScroll) {
				e.preventDefault();
				$root.animate(
					{
						scrollTop: elToScroll.offsetTop - $(".header").height() - 120
					},
					600
				);
				window.location.hash = "#" + hash[1];
				return false;
			}
		}
	});

	// on page load scroll to anchor defined in url hash
	$(document).ready(function() {
		if (target) {
			// window.location.hash = ""; // remove hash from url
			setTimeout(function() {
				$root.animate(
					{
						scrollTop:
							$("#" + target).offset().top - $(".header").height() - 120
					},
					700
				);
				window.location.hash = "#" + target; // add the hash back to url in case it should be needed for other functions
				return false;
			}, 500);
		}
	});
})(jQuery);
